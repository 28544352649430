<template>
  <a-layout class='index animated fadeIn'>
    <a-card>
      <div class="mb15">
        <span class='mr15'>
          <a-button type="primary" v-has:brand_create="()=> {$router.push('/brand/brand_list/brand_create?type=add')}">
            <PlusOutlined />
            添加品牌商
          </a-button>
        </span>
      </div>
      <a-form ref='form' :model='search' :initialValues="search" :onFinish='pageSearchChange' layout='inline'>
        <a-form-item label="关键词" name='keywords'>
          <a-input v-model:value="search.keywords" style="width:250px" placeholder="请输入品牌商编号/账号"></a-input>
        </a-form-item>
        <a-form-item label="品牌商状态" name='status'>
          <a-select v-model:value="search.status" :style="{width:'180px'}" placeholder="全部">
            <a-select-option value="">
              全部
            </a-select-option>
            <a-select-option :value="1">
              正常
            </a-select-option>
            <a-select-option :value="0">
              禁用
            </a-select-option>
            <a-select-option :value="-1">
              已过期
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="品牌商费用状态" name='time_type'>
          <a-select v-model:value="search.time_type" :style="{width:'180px'}" placeholder="全部">
            <a-select-option value="">
              全部
            </a-select-option>
            <a-select-option :value="1">
              正常期
            </a-select-option>
            <a-select-option :value="2">
              续费期
            </a-select-option>
            <a-select-option :value="3">
              已过期
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="签约时间" name='time'>
          <a-range-picker v-model:value="time" valueFormat='YYYY-MM-DD' format='YYYY-MM-DD' @change="onChange" />
        </a-form-item>
        <a-form-item label="余额低于" name='keywords' style="marginTop:10px">
          <!-- v-model:value="search.keywords" -->
          <a-input-number v-model:value="search.balance " style="width:250px" placeholder="请输入余额" :min="0">
          </a-input-number>
        </a-form-item>
        <a-form-item label="产线" name='is_production_line' style="marginTop:10px">
          <a-select v-model:value="search.is_production_line" :style="{width:'180px'}" placeholder="全部">
            <a-select-option value="">
              全部
            </a-select-option>
            <a-select-option :value="1">
              是
            </a-select-option>
            <a-select-option :value="0">
              否
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item>
          <a-button type="primary" htmlType='submit' style="marginTop:10px">搜索</a-button>
        </a-form-item>
        <a-form-item>
          <a-button type="primary" style="marginTop:10px" ghost v-has:brandExport="exportTable">导出</a-button>
        </a-form-item>
      </a-form>
      <a-table :pagination='false' :loading='listLoading' bordered rowKey='id' :columns='columns' :data-source='data' class="tableFixBox m-t-20" :scroll="{ x: 'max-content'}">
        <template v-slot:signing_time="{ record }">
          <p> {{record.signing_start_time}}</p>
          <p> {{record.signing_end_time}}</p>
        </template>
        <template v-slot:action="{ record }">
          <a-dropdown>
            <a class="ant-dropdown-link"> 操作
              <DownOutlined />
            </a>
            <template v-slot:overlay>
              <a-menu>
                <a-menu-item>
                  <a v-has:brand_detail="()=> {$router.push('/brand/brand_list/detail?type=list&id='+record.id)}" href="javascript:;">详情</a>
                </a-menu-item>
                <a-menu-item>
                  <a v-has:brand_edit="()=> {$router.push('/brand/brand_list/brand_edit?type=edit&&id='+record.id)}" href="javascript:;">修改</a>
                </a-menu-item>
                <a-menu-item>
                  <a v-has:brandDisable="()=>brandDisable(record)" href="javascript:;">{{record.status ? '禁用':'启用'}}</a>
                </a-menu-item>
                <!-- <a-menu-item>
                  <a v-has:brandRecharge="()=>brandRecharge(record,1)" href="javascript:;">充值</a>
                </a-menu-item> -->
                <a-menu-item>
                  <a v-has:brandCommission="()=>brandRecharge(record,2)" href="javascript:;">收取佣金设置</a>
                </a-menu-item>
                <a-menu-item v-if="record.app_is_super==0">
                  <a v-has:brandModuleRenew="()=>renew(record,'buy')" href="javascript:;">模块续费</a>
                </a-menu-item>
                <a-menu-item v-if="record.app_is_super==1">
                  <a v-has:brandAllRenew="()=>renew(record,'buy')" href="javascript:;">全包续费</a>
                </a-menu-item>
                <!-- app_is_super 1 全包 0 自选 -->
                <a-menu-item>
                  <a v-has:brandOpenModule="()=>openModule(record)" href="javascript:;">开通新模块</a>
                </a-menu-item>
                <!-- <a-menu-item v-if="record.app_fees_type==1&&record.is_sys_brand==1">
                  <a v-has:brandCommission="()=>upDateModule(record,'up')" href="javascript:;">升级</a>
                </a-menu-item> -->
                <!-- <a-menu-item v-if="record.app_fees_type==1&&record.is_sys_brand==1">
                  <a v-has:brandCommission="()=>changeFreesModel(record)" href="javascript:;">收费模式变更申请</a>
                </a-menu-item> -->
                <a-menu-item>
                  <a v-has:brandLoginSet="()=>{brandItem=record;loginVisible=true}" href="javascript:;">登录控制</a>
                </a-menu-item>
                <a-menu-item v-if="record.status_text!='已过期'">
                  <a v-has:brandSMSRecharge="()=>SMSRecharge(record)" href="javascript:;">短信充值</a>
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>
        </template>
        <template v-slot:brand_apps="{ record }">
          <a-popover placement="top">
            <template v-slot:content>
              <p v-for="(item,index) in record.brand_apps" :key="index">{{item.app_name || '暂无'}}</p>
            </template>
            <!-- <template v-slot:title>
            </template> -->
            <a href="#">查看</a>
          </a-popover>
        </template>
      </a-table>
      <a-pagination :style="{marginTop:'20px',textAlign:'right'}" show-size-changer show-quick-jumper :current="search.page" :page-size="search.limit" :total="total" @change="pageCurrentChange" @showSizeChange="pageSizeChange" :show-total="total => `总数: ${total} `" />
    </a-card>
  </a-layout>
  <a-modal v-model:visible="visible" title="品牌商充值" centered @ok="handleOk(1)" @cancel='price = ""' :confirm-loading="confirmLoading">
    <a-input-number v-model:value="price" placeholder='请输入要充值的金额' style='width:100%' v-inputFilter:price>
    </a-input-number>
  </a-modal>
  <a-modal v-model:visible="visibleC" title="每单收取佣金设置" centered @ok="handleOk(2)" @cancel='commission = ""' :confirm-loading="confirmLoading" :width="800">
    <div class="s_flex_ali mb10">
      <p style="width:180px">分账账户</p>
      <a-radio-group name="radioGroup" :default-value="1" v-model:value="commissionForm.is_charge">
        <a-radio :value="1">
          开启
        </a-radio>
        <a-radio :value="0">
          关闭
        </a-radio>
      </a-radio-group>
    </div>
    <div class="s_flex_ali mb10">
      <p style="width:180px">佣金</p>
      <a-radio-group name="radioGroup" :default-value="1" v-model:value="commissionForm.charge_type">
        <a-radio :value="1">
          比例
        </a-radio>
        <a-radio :value="2">
          固定金额
        </a-radio>
      </a-radio-group>
    </div>
    <div class="s_flex_ali mb10">
      <p style="width:180px">收取佣金的最低商品单价</p>
      <a-input-number v-model:value="commissionForm.least_order_price" :min="5" :disabled="commissionForm.is_charge==0" placeholder='请输入金额' style='width:50%' v-inputFilter:price></a-input-number>
      <p class="ml15">元(最小设置5元)</p>
    </div>
    <div class="s_flex_ali" v-if="commissionForm.charge_type==2">
      <p style="width:180px">收取该品牌商每单</p>
      <a-input-number v-model:value="commissionForm.charge_price" placeholder='请输入金额' :disabled="commissionForm.is_charge==0" style='width:50%' v-inputFilter:price></a-input-number>
      <p class="ml15 pr15">元佣金</p>
    </div>
    <div class="s_flex_ali mb10" v-if="commissionForm.charge_type==1">
      <p style="width:180px">收取该品牌商每单</p>
      <a-input-number v-model:value="commissionForm.charge_percent" :max="99.4" :min="0" :disabled="commissionForm.is_charge==0" placeholder='请输入金额' style='width:50%'></a-input-number>
      <p class="ml15 pr15">%</p>
    </div>
    <div class="s_flex_ali mb10" v-if="commissionForm.charge_percent&&commissionForm.charge_type==1&&commissionForm.least_order_price">
      <p style="width:180px">佣金</p>
      {{(commissionForm.charge_percent*commissionForm.least_order_price/100).toFixed(2)}}
    </div>
  </a-modal>
  <!-- 升级选择 -->
  <a-modal v-model:visible="visibleUp" title="升级管理 - 未在使用的应用列表" :width='800' destroyOnClose centered :footer="null">
    <a-table :pagination='false' :loading='listLoading' bordered rowKey='id' :columns='selectColumns' :data-source='buyData'>
      <template v-slot:action="{ record }">
        <a-button type="primary" @click='select(record,"up")'>选择</a-button>
      </template>
    </a-table>
  </a-modal>
  <!--套餐详情 -->
  <a-modal v-model:visible="visiblePackages" title="" :width='800' destroyOnClose centered :footer="null">
    <a-table :pagination='false' :loading='listLoading' bordered rowKey='id' :columns='packagesColumns' :data-source='packages'>
      <template v-slot:action="{ record }">
        <a-button type="primary" @click='selectPackages(record)'>选择</a-button>
      </template>
    </a-table>
  </a-modal>
  <!-- 续费选择 -->
  <a-modal v-model:visible="visibleBuy" title="品牌续费" :width='800' destroyOnClose centered @ok="submitRenew">
    <!-- <a-table :pagination='false' :loading='listLoading' bordered rowKey='app_info_id' :columns='selectColumns'
      :data-source='buyData'>
      <template v-slot:action="{ record }">
        <a-button type="primary" @click='select(record,"buy")'>选择</a-button>
      </template>
    </a-table> -->

    <a-form ref='formRef' :model='moduleInfo' :labelCol="{span:4,offset:0}">
      <a-form-item label="公司名称">
        <a-input v-model:value="moduleInfo.company" style="width:300px" placeholder="请输入公司名称" disabled></a-input>
      </a-form-item>
      <br>
      <a-form-item label="账号">
        <a-input v-model:value="moduleInfo.account" style="width:300px" placeholder="请输入账号" disabled></a-input>
      </a-form-item>
      <a-form-item label="续费模块:" v-if="app_is_super==0">
        <a-row>
          <a-col :span="8" v-for="(item,index) in moduleList" :key="index" style="marginTop:8px">
            <span>{{index+1}}、{{item.name}}</span>
          </a-col>
          <div v-if="moduleList.length==0" style="color:#999">暂无模块</div>
        </a-row>
      </a-form-item>
      <a-form-item label="续费模块:" v-if="app_is_super==1">
        <span>平台全包</span>
      </a-form-item>
      <a-form-item label="续费年限" style="marginTop:40px" required name="renew_year" :rules="[{ required: true, message: '请选择续费年限！' }]">
        <a-select v-model:value="moduleInfo.renew_year" :style="{width:'180px'}" placeholder="请选择" @change="changeSelect">
          <a-select-option :value="1">
            1年
          </a-select-option>
          <a-select-option :value="2">
            2年
          </a-select-option>
          <a-select-option :value="3">
            3年
          </a-select-option>
          <a-select-option :value="4">
            4年
          </a-select-option>
          <a-select-option :value="5">
            5年
          </a-select-option>
        </a-select>
      </a-form-item>
    </a-form>
    <div style="paddingLeft:50px;marginTop:40px">本次费用合计：<span style="color:#e0001b">{{service_price}}元</span></div>
  </a-modal>

  <!-- 开通新模块 -->
  <a-modal v-model:visible="show" title="开通新模块" :width='800' destroyOnClose @ok="submitOpen">
    <a-form ref='form' :model='moduleInfo.company' :labelCol="{span:4,offset:0}">
      <a-form-item label="公司名称">
        <a-input v-model:value="moduleInfo.company" style="width:300px" placeholder="请输入公司名称" disabled></a-input>
      </a-form-item>
      <br>
      <a-form-item label="账号">
        <a-input v-model:value="moduleInfo.account" style="width:300px" placeholder="请输入账号" disabled></a-input>
      </a-form-item>
      <a-form-item label="收费方式：按功能模块收费" :labelCol="{span:24,offset:2}" :wrapper-col="{ span: 20,offset:4 }" style="position: relative;">
        <a-checkbox-group v-model:value="allCheckbox" @change="changeCheckbox">
          <a-row>
            <a-col :span="8" v-for="(item,index) in moduleList" :key="index" style="marginTop:10px">
              <a-checkbox :value="item.mark_id" :disabled="disFun(item.mark_id)">{{item.name}}</a-checkbox>
            </a-col>
          </a-row>
        </a-checkbox-group>
        <div v-if="moduleList.length==0" style="color:#999">暂无模块</div>
        <div style="display:flex;marginTop:20px;" class="active_box">
          <div style="color:#999">新模块计算方式
            <QuestionCircleFilled style="color:#999;marginLeft:5px" />
          </div>
          <div style="width:320px;background:#595959;color:#fff;padding:10px;marginLeft:10px;line-height: 150%;" class="active_tip">
            <div>收费：如果A模块开通半年后，再新开通B模块，则B模块的收费按照B模块收费计算.</div>
            <div style="marginTop:10px">有效期：B模块的到期时间与A模块的到期时间一致.</div>
          </div>
        </div>
      </a-form-item>
    </a-form>
    <div style="paddingLeft:50px;marginTop:60px">本次费用合计：<span style="color:#e0001b">{{service_price}}元</span></div>
  </a-modal>
  <a-modal v-model:visible="loginVisible" title="登录控制" :width='400' centered @ok="loginHandleOk" @cancel='loginVisible = false' :confirm-loading="confirmLoading">
    <a-form>
      <a-form-item label="登录方式" required>
        <a-checkbox v-model:checked="brandItem.is_account_login">账号登录</a-checkbox>
        <a-checkbox v-model:checked="brandItem.is_wechat_login">微信登录</a-checkbox>
        <a-tooltip placement='right' :overlayStyle="{maxWidth:'300px'}">
          <template #title>
            控制登录品牌后台用户登录时使用的方式，勾选表示支持该登录方式，全部勾选表示该品牌商用户可以使用账号/密码登录也可以使用微信扫码登录
          </template>
          <QuestionCircleOutlined />
        </a-tooltip>
      </a-form-item>
      <a-form-item label="登陆控制">
        <p class="s_c376 text_uline s_shou" @click="brandLoginOut">强制登出品牌子账号</p>
      </a-form-item>
    </a-form>
  </a-modal>
  <!-- 短信充值 -->
  <a-modal v-model:visible="visibleSms" title="短信充值" centered @ok="handleOkSms()" @cancel='commission = ""' :confirm-loading="confirmLoading" :width="400">
    <a-form ref='formSms' :model='SmsData'>
      <a-form-item label="公司名称">
        {{SmsData.company}}
      </a-form-item>
      <a-form-item label="充值数量" required>
        <a-input-number v-model:value="SmsData.number" :min="0" placeholder='请输入充值短信数量' style='width:100%' v-inputFilter:price></a-input-number>
      </a-form-item>
    </a-form>
  </a-modal>
</template>
<script>
import {
  DownOutlined,
  QuestionCircleFilled,
  PlusOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons-vue";
import { ref, onMounted, inject, reactive, toRefs, h } from "vue";
import { message, Modal } from "ant-design-vue";
import { $iscode } from "@/utils/app";
import {
  getappServeFees,
  brandAppRenewInfo,
  brandAppOpen,
  brandAppRenew,
  brandAppCountPrice,
} from "@/api/product";
import {
  appRenewal,
  updateAppfeesType,
  getUsingApps,
  appsByInfoId,
  unUsingAppsForBrand,
  appBuy,
  brandLoginControl,
  brandForcedLogout,
  brandSms,
} from "@/api/brand";
import config from "@/config";
import {
  getBrandtList,
  setBrandDisable,
  setBrandRecharge,
  setBrandCommission,
  getBrandCommission,
  brandExportLists,
} from "@/api/brand";
// 初始化默认筛选项数值
let defSearch = {
  keywords: "",
  status: "",
  page: 1,
  limit: 10,
  signing_start_time: "",
  signing_end_time: "",
  time_type: "",
  balance: "",
  is_production_line: "",
};
export default {
  components: {
    DownOutlined,
    QuestionCircleFilled,
    PlusOutlined,
    QuestionCircleOutlined,
  },
  setup(props) {
    // // 初始化 分页信息和筛选项信息
    let search = ref({ ...defSearch });
    // 列表数据和列头配置
    let data = ref([]);
    const formRef = ref(null);
    const formSms = ref();
    const SmsData = ref({ number: "" });

    const state = reactive({
      loginVisible: false,
      brandItem: {},
      visibleUp: false, //升级
      listLoading: false,
      visible: false,
      visibleC: false,
      total: 1,
      valueRadio: null,
      time: [],
      price: "",
      commission: "",
      confirmLoading: false,
      visibleBuy: false,
      buyData: [],
      visiblePackages: false,
      packages: [],
      show: false,
      form: {},
      moduleList: [], //模块
      allCheckbox: [],
      moduleInfo: {
        company: "",
        account: "",
        renew_year: "",
      },
      app_is_super: 0, // 1全包 0自选
      service_price: 0, //服务价
      visibleSms: false,
    });
    let columns = [
      {
        title: "品牌商编号",
        dataIndex: "brand_no",
        key: "brand_no",
      },
      {
        title: "公司名称",
        dataIndex: "company",
        key: "company",
      },
      {
        title: "品牌商账号",
        dataIndex: "account",
        key: "account",
      },

      {
        title: "账号余额",
        dataIndex: "balance",
        key: "balance",
      },
      {
        title: "有效期",
        dataIndex: "signing_time",
        key: "signing_time",
        slots: { customRender: "signing_time" },
      },
      {
        title: "佣金(元)",
        dataIndex: "commission_per_order",
        key: "commission_per_order",
      },
      {
        title: "状态",
        dataIndex: "status_text",
        key: "status_text",
      },
      {
        title: "微信授权",
        dataIndex: "wechat_auth_text",
        key: "wechat_auth_text",
      },
      {
        title: "认证状态",
        dataIndex: "is_certificate_text",
        key: "is_certificate_text",
      },
      {
        title: "产线",
        dataIndex: "is_production_line",
        key: "is_production_line",
        customRender: ({ text, record }) => {
          return record.is_production_line ? "是" : "否";
        },
      },
      {
        title: "已订购功能模块",
        dataIndex: "brand_apps",
        key: "brand_apps",
        slots: { customRender: "brand_apps" },
      },
      {
        title: "创建时间",
        dataIndex: "create_time",
        key: "create_time",
      },
      {
        title: "操作",
        key: "action",
        fixed: "right",
        slots: { customRender: "action" },
      },
    ];
    let selectColumns = [
      {
        title: "应用名称",
        dataIndex: "app_name",
        key: "app_name",
      },
      {
        title: "操作",
        key: "action",
        width: 100,
        slots: { customRender: "action" },
      },
    ];
    let packagesColumns = [
      {
        title: "id",
        dataIndex: "id",
        key: "id",
      },
      {
        title: "年限",
        dataIndex: "valid_year",
        key: "valid_year",
      },
      {
        title: "价格",
        dataIndex: "real_price",
        key: "real_price",
      },
      {
        title: "操作",
        key: "action",
        width: 100,
        slots: { customRender: "action" },
      },
    ];
    var appColumns = ref([
      {
        title: "年限",
        dataIndex: "valid_year",
        key: "valid_year",
      },
      {
        title: "价格",
        dataIndex: "sys_price",
        key: "sys_price",
      },
      {
        title: "操作",
        key: "action",
        width: 100,
        slots: { customRender: "action" },
      },
    ]);
    let original = []; //原钩选的模块

    const $router = inject("$router");
    const $Modal = inject("$Modal");
    const $route = inject("$route");
    const _lodash = inject("_lodash");

    // 页面筛选项搜索
    const pageSearchChange = () => {
      search.value = { ...search.value, page: 1 };
      initData(search.value);
    };
    // 分页当前页切换
    const pageCurrentChange = (page, pageSize) => {
      search.value.page = page;
      initData(search.value);
    };
    // 分页当前页显示多少条切换
    const pageSizeChange = (current, size) => {
      search.value.page = 1;
      search.value.limit = size;
      initData(search.value);
    };
    //品牌商禁用
    const brandDisable = (e) => {
      console.log(e);
      $Modal.confirm({
        title: "品牌商" + (e.status ? "禁用" : "启用"),
        centered: true,
        content: h("div", {}, [
          h(
            "p",
            { className: "mb10" },
            "您确定要" + (e.status ? "禁用" : "启用") + "该品牌商吗？"
          ),
          h(
            "p",
            { className: "tis" },
            e.status ? "提示：禁用后该品牌商将被直接禁用！请谨慎操作！" : ""
          ),
        ]),
        onOk: async () => {
          let res = await setBrandDisable(e.id, e.status ? 0 : 1).then(
            (res) => res.data
          );
          if ($iscode(res)) {
            message.success(res.msg);
            initData(search.value);
          } else {
            message.error(res.msg);
          }
        },
      });
    };
    const commissionForm = ref({
      is_charge: 1,
      charge_type: 1,
      charge_percent: "",
      charge_price: "",
      least_order_price: 5,
    });
    //品牌商充值
    const brandRecharge = async (e, type) => {
      if (type == 1) {
        state.visible = true;
      } else {
        let result = await getBrandCommission({ brand_id: e.brand_no })
          .then((res) => res.data)
          .catch((error) => error);
        if (result.data) {
          commissionForm.value = {
            is_charge: Number(result.data.is_charge),
            charge_type: Number(result.data.charge_type),
            charge_percent: result.data.charge_percent,
            charge_price: result.data.charge_price,
            least_order_price: result.data.least_order_price,
          };
        } else {
          commissionForm.value = {
            is_charge: 1,
            charge_type: 1,
            charge_percent: "",
            charge_price: "",
            least_order_price: 5,
          };
        }
        state.visibleC = true;
      }
      state.brandItem = e;
    };
    const handleOk = async (type) => {
      if (state.price == "" && type == 1) {
        message.error("请输入充值金额");
        return false;
      }
      state.confirmLoading = true;
      let res;
      if (type == 1) {
        res = await setBrandRecharge({
          brand_id: state.brandItem.brand_no,
          number: state.price,
        }).then((res) => res.data);
      } else {
        if (commissionForm.value.is_charge == 2) {
          $Modal.confirm({
            content: "品牌商分账账户关闭以后,无法收取品牌商每单的佣金",
            centered: true,
            onOk: async () => {
              res = await setBrandCommission({
                ...commissionForm.value,
                id: state.brandItem.id,
                brand_id: state.brandItem.brand_no,
                commission: state.commission,
              }).then((res) => res.data);
              state.confirmLoading = false;
              if ($iscode(res)) {
                message.success(res.msg);
                initData(search.value);
                state.visible = false;
                state.visibleC = false;
                commissionForm.value = {
                  is_charge: 1,
                  charge_type: 1,
                  charge_percent: "",
                  charge_price: "",
                  least_order_price: 5,
                };
              } else {
                message.error(res.msg);
              }
            },
            onCancel() {
              state.visible = false;
              state.visibleC = false;
              state.confirmLoading = false;
            },
          });
        } else {
          res = await setBrandCommission({
            ...commissionForm.value,
            id: state.brandItem.id,
            brand_id: state.brandItem.brand_no,
            commission: state.commission,
          }).then((res) => res.data);
          state.confirmLoading = false;
        }
      }
      if ($iscode(res)) {
        message.success(res.msg);
        initData(search.value);
        state.visible = false;
        state.visibleC = false;
        commissionForm.value = {
          is_charge: 1,
          charge_type: 1,
          charge_percent: "",
          charge_price: "",
          least_order_price: 5,
        };
      } else {
        message.error(res.msg);
      }
    };
    // 时间筛选
    const onChange = (e) => {
      console.log(e);
      if (e.length > 0) {
        search.value.signing_start_time = e[0];
        search.value.signing_end_time = e[1];
      } else {
        search.value.signing_start_time = "";
        search.value.signing_end_time = "";
      }
    };
    const initData = async (values) => {
      state.listLoading = true;
      data.value = [];
      try {
        let res = await getBrandtList(values).then((res) => res.data);
        state.listLoading = false;
        console.log(res);
        if ($iscode(res)) {
          data.value = res.data.data;
          state.total = res.data.total;
        } else {
          message.error(res.message);
        }
      } catch (e) {
        state.listLoading = false;
      }
    };

    // 开通新模块
    const openModule = async function (record) {
      await getModule(record.brand_no);
      state.service_price = 0;
      state.show = true;
    };

    // 短信充值
    const SMSRecharge = async function (record) {
      SmsData.value.company = record.company;
      SmsData.value.brand_id = record.brand_no;
      state.visibleSms = true;
    };
    //充值确定
    const handleOkSms = function () {
      formSms.value.validate().then(async (data) => {
        const hide = message.loading("正在加载中...", 0);
        console.log(SmsData.value);
        let res = await brandSms({
          brand_id: SmsData.value.brand_id,
          number: SmsData.value.number,
        }).then((res) => res.data);
        setTimeout(hide);
        if ($iscode(res)) {
          message.success(res.msg);
          initData(search.value);
          state.visibleSms = false;
        } else {
          message.error(res.msg);
        }
      });
    };
    //判断是否钩选
    const disFun = function (id) {
      return original.some((item) => item == id);
    };

    //功能模块
    const getModule = async function (id) {
      let res = await brandAppRenewInfo({ brand_id: id }).then(
        (res) => res.data
      );
      if ($iscode(res)) {
        state.moduleList = res.data.app_list || [];
        state.allCheckbox = res.data.app_list.map((item) => {
          if (item.is_open == 1) {
            return item.mark_id;
          }
        });
        original = state.allCheckbox;
        state.moduleInfo = res.data || {};
        state.moduleInfo.brand_id = id || "";
        if (state.show) {
          //开通
          countPrice();
        }
      } else {
        message.error(res.msg);
      }
    };

    //切换模块
    const changeCheckbox = async function (e) {
      // console.log(e,'11')
      state.allCheckbox = e;
      countPrice();
    };
    //计算价格
    const countPrice = async function () {
      const hide = message.loading("正在加载中...", 0);
      let res = await brandAppCountPrice({
        brand_id: state.moduleInfo.brand_id,
        mode: 2,
        mark_ids: _lodash.pull(state.allCheckbox, "0"),
      }).then((res) => res.data);
      setTimeout(hide);
      if ($iscode(res)) {
        state.service_price = res.data || 0;
      } else {
        message.error(res.msg);
      }
    };

    //开通
    const submitOpen = async function () {
      const hide = message.loading("正在加载中...", 0);
      let res = await brandAppOpen({
        mark_ids: _lodash.pull(state.allCheckbox, "0"),
        brand_id: state.moduleInfo.brand_id,
      }).then((res) => res.data);
      setTimeout(hide);
      if ($iscode(res)) {
        message.success("开通成功");
        initData(search.value);
        state.show = false;
      } else {
        message.error(res.msg);
      }
    };

    //续费
    const submitRenew = function () {
      formRef.value.validate().then(async (data) => {
        const hide = message.loading("正在加载中...", 0);
        let res = await brandAppRenew({
          brand_id: state.moduleInfo.brand_id,
          renew_year: state.moduleInfo.renew_year,
        }).then((res) => res.data);
        setTimeout(hide);
        if ($iscode(res)) {
          message.success("续费成功");
          initData(search.value);
          state.visibleBuy = false;
        } else {
          message.error(res.msg);
        }
      });
    };

    //年限--计算价格
    const changeSelect = async function (e) {
      const hide = message.loading("正在加载中...", 0);
      let res = await brandAppCountPrice({
        brand_id: state.moduleInfo.brand_id,
        mode: 1,
        real_valid_year: e,
      }).then((res) => res.data);
      setTimeout(hide);
      if ($iscode(res)) {
        state.service_price = res.data || 0;
      } else {
        message.error(res.msg);
      }
    };

    onMounted(() => {
      if ($route.query.time_type) {
        search.value.time_type = Number($route.query.time_type) || "";
      }
      console.log($route.query.time_type);
      initData(search.value);
    });

    function useFreeHook() {
      return {
        async upDateModule(value) {
          let res = await unUsingAppsForBrand({
            brand_id: value.brand_no,
          }).then((res) => res.data);
          if ($iscode(res)) {
            state.buyData = res.data;
            if (state.buyData.length == 0) {
              message.info("该品牌商已最高级");
              return;
            }
            state.visibleUp = true;
            state.brand_id = value.brand_no;
            state.handle_type = 2;
            state.app_fees_type = value.app_fees_type;
            state.type = "up";
          } else {
            message.error(res.msg);
          }
        },
        async renew(value) {
          state.type = "buy";
          console.log(value);
          // const hide = message.loading("正在加载中...", 0);
          state.app_is_super = value.app_is_super;
          await getModule(value.brand_no);
          state.moduleList = state.moduleList.filter(
            (item) => item.is_open == 1
          );
          state.service_price = 0;
          state.moduleInfo.renew_year = 1;
          await changeSelect(1);
          state.visibleBuy = true;
          // if(value.app_is_super==1){ //全包模块

          // let result= await getappServeFees().then(res=>res.data).catch(error=>error)
          //  Modal.confirm({
          //   content: `确定要为该品牌商续费,当前操作使用${result.data.serve_price}`, onOk:async () => {
          //     let res=await appRenewal({brand_id:value.brand_no,id:result.data.id,app_fees_type:value.app_fees_type}).then(res=>res.data).catch(error=>error)
          //     $iscode(res,true)
          //   }
          // })
          // setTimeout(hide);
          // }else{ //自选模块
          //  let res = await getUsingApps({ brand_id: value.brand_no }).then(res => res.data);
          // if ($iscode(res)) {
          //   state.buyData = res.data
          //   state.visibleBuy = true
          //   state.brand_id = value.brand_no
          //   state.handle_type=2
          //   state.app_fees_type=value.app_fees_type
          // } else {
          //   message.error(res.msg);
          // }
          // setTimeout(hide);
          // }
        },
        async changeFreesModel(value) {
          let result = await getappServeFees()
            .then((res) => res.data)
            .catch((error) => error);
          const hide = message.loading("正在加载中...", 0);
          Modal.confirm({
            content: `确定要为该品牌商切换为服务费模式,当前操作使用${result.data.serve_price}`,
            centered: true,
            onOk: async () => {
              let res = await updateAppfeesType({ brand_id: value.brand_no })
                .then((res) => res.data)
                .catch((error) => error);
              $iscode(res, true);
            },
          });
          setTimeout(hide);
        },
      };
    }
    // 选择应用
    const select = async (record, type) => {
      // if(type=='buy'){
      let result = await appsByInfoId({
        handle_type: type == "buy" ? 2 : 1,
        app_fees_type: state.app_fees_type,
        app_id: record.app_id || record.id,
      })
        .then((res) => res.data)
        .catch((error) => error);
      console.log(result);
      if ($iscode(result, true)) {
        state.packages = result.data;
        state.visiblePackages = true;
      }
      // }else{

      // }
    };
    const selectPackages = async (record, type) => {
      if (state.type == "buy") {
        let res = await appRenewal({
          brand_id: state.brand_id,
          id: record.id,
          app_fees_type: state.app_fees_type,
        })
          .then((res) => res.data)
          .catch((error) => error);
        if ($iscode(res, true)) {
          state.visibleBuy = false;
          state.visibleUp = false;
        }
      } else {
        let result = await appBuy({
          handle_type: 2,
          app_fees_type: state.app_fees_type,
          id: record.id,
          brand_id: state.brand_id,
        })
          .then((res) => res.data)
          .catch((error) => error);
        if ($iscode(result, true)) {
          state.packages = result.data;
          state.visiblePackages = false;
        }
      }
    };
    // 续费 和切换模式
    const { renew, changeFreesModel, upDateModule } = useFreeHook();

    //导出
    const exportTable = async () => {
      const hide = message.loading("正在加载中...", 0);
      let result = await brandExportLists({ ...search.value })
        .then((res) => res.data)
        .catch((error) => error);
      if ($iscode(result, true)) {
        window.open(
          config.baseUrl[process.env.VUE_APP_type] + result.data.file_url
        );
      }
      setTimeout(hide);
    };
    const loginHandleOk = async () => {
      if (
        !state.brandItem.is_wechat_login &&
        !state.brandItem.is_account_login
      ) {
        message.error("请选择登录方式");
      } else {
        state.confirmLoading = true;
        let result = await brandLoginControl({
          brand_id: state.brandItem.brand_no,
          is_wechat_login: state.brandItem.is_wechat_login ? 1 : 0,
          is_account_login: state.brandItem.is_account_login ? 1 : 0,
        })
          .then((res) => res.data)
          .catch((error) => error);
        if ($iscode(result, true)) {
          state.loginVisible = false;
          initData(search.value);
        }
        state.confirmLoading = false;
      }
    };
    const brandLoginOut = () => {
      Modal.confirm({
        content: `执行操作后系统将清除该品牌商下所有子账号的登录状态，用户需重新登录，确定继续？`,
        centered: true,
        onOk: async () => {
          let res = await brandForcedLogout({
            brand_id: state.brandItem.brand_no,
          })
            .then((res) => res.data)
            .catch((error) => error);
          $iscode(res, true);
          console.log(123);
        },
      });
    };
    return {
      ...toRefs(state),
      search,
      data,
      columns,
      pageSearchChange,
      pageCurrentChange,
      pageSizeChange,
      onChange,
      brandDisable,
      brandRecharge,
      handleOk,
      commissionForm,
      renew,
      select,
      changeFreesModel,
      appColumns,
      selectColumns,
      packagesColumns,
      selectPackages,
      upDateModule,
      openModule,
      changeCheckbox,
      submitOpen,
      submitRenew,
      formRef,
      disFun,
      original,
      changeSelect,
      exportTable,
      loginHandleOk,
      brandLoginOut,
      SMSRecharge,
      formSms,
      SmsData,
      handleOkSms,
    };
  },
};
</script>
<style scope>
.tis {
  color: #f5222d;
}

.active_tip {
  position: absolute;
  display: none;
}

.active_box:hover .active_tip {
  position: absolute;
  top: 50%;
  left: 130px;
  display: block;
  border-radius: 4px;
  /* transform: translateY(-50%); */
}
</style>